import { getClientSesmt } from './http-sesmt';

export default class SesmtService {
    constructor(path, auxHeaders) {
        this.path = path;
        this.auxHeaders = auxHeaders;
    }

    findAll({ page = 1, limit = 10, filter = '', sort = '', ...otherParams }) {
        return getClientSesmt(this.auxHeaders).get(this.path, {
            params: {
                page,
                limit,
                filter,
                sort,
                ...otherParams
            }
        });
    }

    findById(id) {
        return getClientSesmt(this.auxHeaders).get(`${this.path}/${id}`);
    }

    save(record) {
        if (record.id) {
            return getClientSesmt(this.auxHeaders).patch(`${this.path}/${record.id}`, record);
        }
        return getClientSesmt(this.auxHeaders).post(this.path, record);
    }

    remove(id) {
        return getClientSesmt(this.auxHeaders).delete(`${this.path}/${id}`);
    }

    activate(id) {
        return getClientSesmt(this.auxHeaders).post(`${this.path}/ativar/${id}`);
    }

    inactivate(id) {
        return getClientSesmt(this.auxHeaders).post(`${this.path}/inativar/${id}`);
    }
}

export class SesmtQuestionarioCopiarService extends SesmtService {
    constructor(auxHeaders) {
        super('/questionario-sesmt', auxHeaders);
    }

    save(record) {
        return getClientSesmt(this.auxHeaders).post(`${this.path}/clonar`, record);
    }

    findById(id) {
        return getClientSesmt(this.auxHeaders).get(`${this.path}/${id}`);
    }
}
